import * as React from "react";

import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import { MenuWidgetSpec, Widget, renderPageWidgets } from "../../";
import { Site, Template, WithId } from "@maxxton/cms-api";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../i18n";

import { SitemapPageLinkWidgetOptions } from "../../sitemap/sitemap.types";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import namespaceList from "../../../i18n/namespaceList";
import { templateSpec } from "../../../form-specs/models";

export interface WidgetOptions {
    templateId: string | null;
    styleIds: any[];
    hidden: boolean;
}

const TARGETS = ["menu-template"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "template-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetTemplate, "templateWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetTemplate, "templateWidgetOptions"),
    properties: [
        {
            variable: "templateId",
            label: getI18nLocaleObject(namespaceList.widgetTemplate, "template"),
            type: "autocomplete",
            refType: templateSpec,
        },
        multiSelectStylePicker("styleIds", TARGETS),
        {
            label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "menuItemVisibility"),
            variable: "hidden",
            type: "checkbox",
        },
    ],
};

export const menuTemplateWidget: MenuWidgetSpec<WidgetOptions> = {
    id: "menutemplate",
    type: "menu",
    widgetGroup: WidgetGroup.OTHER,
    name: getI18nLocaleObject(namespaceList.widgetTemplate, "templateWidget"),
    description: getI18nLocaleObject(namespaceList.widgetTemplate, "templateWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        templateId: null,
        styleIds: [],
        hidden: false,
    }),
    container: false,
    // eslint-disable-next-line max-len
    async render(widget: Widget<WidgetOptions>, context, allSites?: Array<Site & WithId>, sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions) {
        const { templateId, styleIds } = widget.options;
        const template: (Template & WithId) | null = templateId ? await context.cmsApi.templateApi.findById({ id: templateId }) : null;
        if (template === null) {
            throw new Error("No template selected");
        }
        const classNames = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        const children = await renderPageWidgets(template.root, context, sitemapPageLinkWidgetOptions);
        return <div className={`template ${classNames}`}>{children}</div>;
    },
    async instanceDescription({ widget, context }): Promise<string> {
        const { templateId } = widget.options;
        const template: Template | null = templateId ? await context.cmsApi.templateApi.findById({ id: templateId }) : null;
        if (template) {
            return template.name;
        }
        return getI18nLocaleString(namespaceList.widgetTemplate, "noTemplate");
    },
};
